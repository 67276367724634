import React from 'react';
import LayoutSwitcher from './layouts/layout-switcher';
import { Direction } from './containers/direction';
import { widgetWrapper } from './worker/widgetWrapper';
import { createStore } from './widget.store';
import { useReloadOnIdsChange } from './utils/useReloadOnIdsChange';
import { WidgetWidthProvider } from './containers/widget-width';

function Component() {
  useReloadOnIdsChange();

  return (
    <WidgetWidthProvider>
      <Direction>
        <LayoutSwitcher />
      </Direction>
    </WidgetWidthProvider>
  );
}

export const DesktopWidgetComponent = widgetWrapper({
  createStore,
  Component,
});
