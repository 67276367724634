import _ from 'lodash';
import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';

export function getPlayButtonHandler(
  onPlayRequest,
  onPlayMemberOnlyRequest,
  canPlayFullOrPreview,
  fullAccessAction,
) {
  if (canPlayFullOrPreview) {
    return onPlayRequest;
  }

  if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.SIGN_IN) {
    return onPlayMemberOnlyRequest;
  }

  return _.noop;
}
