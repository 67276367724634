import {
  getSubscriptionPriceInfo,
  isPricingPlanEnabled,
} from '@wix/wix-vod-shared/common';
import formatMessageWithPrice from '../../../utils/format-message-with-price';
import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import { t } from '../../../utils/translation';

/**
 * Is channel subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible({ channel, video }) {
  const access = getFullAccessActionName(channel, video);

  return (
    access === FULL_ACCESS_ACTION_NAMES.SUBSCRIBE ||
    access === FULL_ACCESS_ACTION_NAMES.PRICING_PLAN
  );
}

/**
 * Channel subscribe button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is 'widget.overlay-buttons.get-subscription'
 * @return {React.Component}
 */
export default function renderSubscribeButtonText(props) {
  if (!isVisible(props)) {
    return null;
  }

  return isPricingPlanEnabled(props.channel)
    ? t('widget.mobile.overlay-button.subscribe.short')
    : formatMessageWithPrice(
        props.translation || 'widget.overlay-buttons.get-subscription',
        getSubscriptionPriceInfo(props.channel.dealInfo),
      );
}
