import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { withTranslation } from '@wix/yoshi-flow-editor';
import focus from '@wix/wix-vod-shared/dist/esm/widget/utils/accessibility-focus';

export default withTranslation()(
  class AccessibleVideosContainer extends React.Component {
    static propTypes = {
      className: PropTypes.string.isRequired,

      channelTitle: PropTypes.string.isRequired,
      isChannelHasVideos: PropTypes.bool.isRequired,
      isVideosFetching: PropTypes.bool.isRequired,
      isSearching: PropTypes.bool.isRequired,
      videoIdsByPageNumber: PropTypes.array.isRequired,
      searchQuery: PropTypes.string,
      currentVideosPageNumber: PropTypes.number.isRequired,

      children: PropTypes.node.isRequired,

      onRef: PropTypes.func,
      style: PropTypes.object,
      dataHook: PropTypes.string,
    };

    static defaultProps = {
      onRef: _.noop,
    };

    componentDidUpdate(prevProps) {
      const { currentVideosPageNumber } = this.props;

      const isPageChanged =
        prevProps.currentVideosPageNumber !== currentVideosPageNumber;
      if (isPageChanged) {
        focus(this.containerRef);
      }

      if (this.checkIsSearchJustEnded(prevProps)) {
        focus(this.containerRef);
      }
    }

    checkIsSearchJustEnded(prevProps) {
      const {
        currentVideosPageNumber,
        videoIdsByPageNumber,
        isVideosFetching,
        isChannelHasVideos,
        isSearching,
      } = this.props;

      if (!prevProps.isSearching || isSearching) {
        return;
      }

      const isVideosFetchingEnded =
        !isVideosFetching && prevProps.isVideosFetching !== isVideosFetching;
      const isVideosPresent = _.get(
        videoIdsByPageNumber[currentVideosPageNumber],
        'length',
      );
      const isVideosAtCurrentPageChanged = !_.isEqual(
        prevProps.videoIdsByPageNumber[currentVideosPageNumber],
        videoIdsByPageNumber[currentVideosPageNumber],
      );

      return (
        isVideosFetchingEnded &&
        isChannelHasVideos &&
        (isVideosAtCurrentPageChanged || !isVideosPresent)
      );
    }

    saveContainerRef = (ref) => {
      this.containerRef = ref;
      this.props.onRef(ref);
    };

    isNoSearchResults() {
      const { videoIdsByPageNumber, searchQuery, isVideosFetching } =
        this.props;
      return !isVideosFetching && searchQuery && !videoIdsByPageNumber.length;
    }

    getVideosContainerAriaLabel() {
      const { channelTitle, currentVideosPageNumber, searchQuery } = this.props;

      if (this.isNoSearchResults()) {
        return null;
      }

      if (searchQuery) {
        const translationKey =
          currentVideosPageNumber === 0
            ? 'widget.accessibility.search-results-for'
            : 'widget.accessibility.search-results-page-n-for';
        return this.props.t(translationKey, {
          searchQuery,
          pageNumber: currentVideosPageNumber + 1,
        });
      }

      const translationKey =
        currentVideosPageNumber === 0
          ? 'widget.accessibility.channel-videos'
          : 'widget.accessibility.channel-videos-page-n';
      return this.props.t(translationKey, {
        channelTitle,
        pageNumber: currentVideosPageNumber + 1,
      });
    }

    render() {
      const { className, style, children, dataHook } = this.props;

      return (
        <section
          className={className}
          style={style}
          data-hook={dataHook}
          ref={this.saveContainerRef}
          tabIndex="0"
          aria-label={this.getVideosContainerAriaLabel()}
        >
          {children}
        </section>
      );
    }
  },
);
