import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ContainerQuery } from 'react-container-query';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { getCurrentSiteUser } from '../../../../selectors/current-site-user';
import { getVideosGroupedByIds } from '../../../../selectors/videos';
import { logWidgetSystem } from '../../../../worker/actions/bi';
import ContentSlider from '@wix/wix-vod-shared/dist/esm/widget/ui-components/content-slider/content-slider';
import PageList from '@wix/wix-vod-shared/dist/esm/widget/ui-components/page-list/page-list';
import Nav from '@wix/wix-vod-shared/dist/esm/widget/ui-components/nav/nav';
import VideoThumbnailOverlay from '../video-thumbnail-overlay/video-thumbnail-overlay';
import styles from './videos.scss';

const containerQuery = {
  'videos-container-min-800': {
    minWidth: 800,
  },
  'videos-container-max-799': {
    maxWidth: 799,
    minWidth: 600,
  },
  'videos-container-max-599': {
    maxWidth: 599,
    minWidth: 550,
  },
  'videos-container-max-549': {
    maxWidth: 549,
    minWidth: 450,
  },
  'videos-container-max-449': {
    maxWidth: 449,
  },
};

const mapStateToProps = (state) => ({
  currentSiteUser: getCurrentSiteUser(state),
  videoByIds: getVideosGroupedByIds(state),
});

const mapDispatchToProps = { logWidgetSystem };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class Videos extends React.Component {
      static propTypes = {
        OverlayActionsCallbacks: PropTypes.func,

        currentSiteUser: PropTypes.object,
        channelData: PropTypes.object,

        containerWidth: PropTypes.number,

        videoByIds: PropTypes.object,
        videoIdsByPageNumber: PropTypes.array,
        isVisible: PropTypes.bool,
        isRTL: PropTypes.bool,

        thumbnailSize: PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
        }),

        onSlideToPrev: PropTypes.func,
        onSlideToNext: PropTypes.func,
        onBeforeClick: PropTypes.func,
        onBeforePlayRequested: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,

        currentVideosPageNumber: PropTypes.number,
      };

      UNSAFE_componentWillReceiveProps({ currentVideosPageNumber }) {
        if (currentVideosPageNumber !== this.props.currentVideosPageNumber) {
          this.props.logWidgetSystem('videoList.changePage.rendered', {
            previousEventName: 'videoList.changePage.requested',
          });
        }
      }

      componentDidUpdate() {
        this.props.logWidgetSystem('videoList.show.rendered', {
          previousEventName: 'videoList.show.requested',
        });
      }

      renderVideoItem = (videoId, videoAtPageIndex, isVisiblePage) => {
        const {
          currentSiteUser,
          channelData,
          videoByIds,
          thumbnailSize,
          OverlayActionsCallbacks,
          onBeforeClick,
          onBeforePlayRequested,
          onPlayRequestedBi,
        } = this.props;

        const videoItem = videoByIds[videoId];

        return (
          <OverlayActionsCallbacks
            channelId={channelData.id}
            videoId={videoItem.id}
            videoSource={videoItem.videoSource}
            onBeforeClick={onBeforeClick}
            onBeforePlayRequested={onBeforePlayRequested}
            onPlayRequestedBi={_.partial(onPlayRequestedBi, videoItem)}
            key={videoId}
          >
            <VideoThumbnailOverlay
              {...thumbnailSize}
              currentSiteUser={currentSiteUser}
              channelData={channelData}
              videoItem={videoItem}
              isContentFocusable={isVisiblePage}
            />
          </OverlayActionsCallbacks>
        );
      };

      hasPage(pageNum, next) {
        const {
          videoIdsByPageNumber,
          channelData: { videosCount },
        } = this.props;

        const requestedPage = pageNum + next;

        if (pageNum === 0 && next > 0) {
          const videosPassed = videoIdsByPageNumber[0]
            ? videoIdsByPageNumber[0].length * requestedPage
            : 0;
          return (
            Boolean(videoIdsByPageNumber[requestedPage]) ||
            videosPassed < videosCount
          );
        }

        return Boolean(videoIdsByPageNumber[requestedPage]);
      }

      get isPrevPageVisible() {
        const { currentVideosPageNumber } = this.props;
        return this.hasPage(currentVideosPageNumber, -1);
      }

      get isNextPageVisible() {
        const { currentVideosPageNumber } = this.props;
        return this.hasPage(currentVideosPageNumber, 1);
      }

      render() {
        const {
          currentVideosPageNumber,
          videoIdsByPageNumber,
          containerWidth,
          onSlideToPrev,
          onSlideToNext,
          isVisible,
          isRTL,
        } = this.props;

        return (
          <ContainerQuery query={containerQuery}>
            {(containerClassNames) => (
              <div
                className={classNames(styles.container, containerClassNames)}
              >
                <ContentSlider
                  width={containerWidth}
                  currentPageNumber={currentVideosPageNumber}
                  isRTL={isRTL}
                >
                  <PageList
                    pageWidth={containerWidth}
                    currentPageNumber={currentVideosPageNumber}
                    itemsByPageNumber={videoIdsByPageNumber}
                    renderItem={this.renderVideoItem}
                  />
                </ContentSlider>
                {isVisible && (
                  <Nav
                    prevButtonClassName={styles['prev-button']}
                    nextButtonClassName={styles['next-button']}
                    onPrevClick={onSlideToPrev}
                    onNextClick={onSlideToNext}
                    prevButtonAriaLabel={this.props.t(
                      'widget.accessibility.prev-videos',
                    )}
                    nextButtonAriaLabel={this.props.t(
                      'widget.accessibility.next-videos',
                    )}
                    isPrevVisible={this.isPrevPageVisible}
                    isNextVisible={this.isNextPageVisible}
                    isRTL={isRTL}
                  />
                )}
              </div>
            )}
          </ContainerQuery>
        );
      }
    },
  ),
);
