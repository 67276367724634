import {
  canSubscribeToChannel,
  canSubscribeToChannelByPricingPlan,
} from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/channel-access';

/**
 * Is video subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @return {Boolean}
 */
export function isSubscriptionButtonVisible({ channel = {} }) {
  return (
    canSubscribeToChannel(channel) ||
    canSubscribeToChannelByPricingPlan(channel)
  );
}
