import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withFedopsLogger } from '@wix/yoshi-flow-editor';
import Compact from './components/layouts/compact/compact';
import { Vertical } from './components/layouts/vertical/vertical';
import PaymentEvents from '../../../components/payment-events/payment-events';
import DeeplinkPopups from '../../../components/deeplink-popups/deeplink-popups';
import { logWidgetSystem } from '../../../worker/actions/bi';
import { logWidgetVidClick } from '../../../utils/bi/widget-common-events';
import { MobilePerformanceLoggers } from '../../../containers/performance-loggers/performance-loggers';

import {
  getAllSettings,
  isVerticalLayoutMobile,
} from '../../../selectors/app-settings';
import { getVideosGroupedByIds } from '../../../selectors/videos';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/selected-video-id';
import { sendLoadComplete } from '../../../utils/load-complete';
import {
  isPlayingOptimistic,
  isInitial,
  isEnded,
} from '../../../selectors/playback';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { getCurrentSiteUser } from '../../../selectors/current-site-user';
import { openVideoPage } from '../../../redux/actions/mobile-overlay-openners/main-openners';
import { openSubscribeOverlayFromWidget } from '../../../redux/actions/mobile-overlay-openners/payment-pages-actions';
import {
  selectVideo,
  resetSelectedVideo,
} from '../../../redux/actions/select-video';
import { requestPlay, requestPause } from '../../../redux/reducers/playback';
import { canPlayVideoInFrame } from './ui-selectors/can-play-video-in-frame';
import { isMainItemChannelCover } from './ui-selectors/main-item-cover';
import {
  getVideoIds,
  getIsFetching,
} from '../../../redux/lazy-channel-videos/selectors';
import { isSingleVideoView } from './ui-selectors/videos';
import {
  overlaySizeMobileMainItem,
  playerSizeMobileMainItem,
} from '../../../utils/videos-sizes/videos-sizes';
import { PersistentEvents } from '../../../components/persistent-events/persistent-events';
import { openMobileOverlay } from '../../../utils/open-overlay-base';
import { storeForReload } from '../../../utils/reload';
import { sendBiEvent } from '../../../bi/send-bi-event';
import { notForPreview } from '../../../utils/not-for-preview';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import { requestLogin } from '../../../utils/auth';
import { playbackModuleLoaded } from '../../redux/actions/modules';
import { getHydratedData } from '../../../redux/hydrated-data/hydrated-data';
import styles from './home.scss';
import { getMainVideo } from '../../../selectors/get-video';
import { isMobile } from '../../../selectors/form-factor';

const mapStateToProps = (state) => ({
  isVideoPlaying:
    Boolean(state.selectedVideoId) &&
    isPlayingOptimistic(state, state.selectedVideoId),
  playback: state.playback,
  canShowChannelCover: !state.isVideosTouched,
  appSettings: getAllSettings(state),
  videoByIds: getVideosGroupedByIds(state),
  videoIds: getVideoIds(state),
  isFetching: getIsFetching(state),
  channelData: getChannelForWidget(state),
  currentSiteUser: getCurrentSiteUser(state),
  mainVideoId: getMainVideoId(state),
  mainVideo: getMainVideo(state),
  canPlayVideoInFrame: canPlayVideoInFrame(state),
  isMainItemChannelCover: isMainItemChannelCover(state),
  isSingleVideoView: isSingleVideoView(state),
  overlaySize: overlaySizeMobileMainItem(state.windowSize.width),
  playerSize: playerSizeMobileMainItem(state.windowSize.width),
  staticsVersion: getHydratedData(state).staticsVersion,
  windowSize: state.windowSize,
  isVerticalLayoutMobile: isVerticalLayoutMobile(state),
  isMobile: isMobile(state),
});

const mapDispatchToProps = {
  openVideoPage,
  selectVideo,
  resetSelectedVideo,
  requestPlay,
  requestPause,
  openSubscribeOverlayFromWidget,
  playbackModuleLoaded,
  logWidgetSystem,
  requestLogin,
  sendLoadComplete,
  logWidgetVidClick,
  storeForReload,
  sendBiEvent,
  openMobileOverlay,
};

const ConnectedHomeView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class HomeView extends React.Component {
    static displayName = 'MobileView';

    static propTypes = {
      staticsVersion: PropTypes.string,
      appSettings: PropTypes.object.isRequired,
      channelData: PropTypes.object.isRequired,
      selectVideo: PropTypes.func.isRequired,
      canShowChannelCover: PropTypes.bool.isRequired,
      isVideoPlaying: PropTypes.bool.isRequired,
      isSingleVideoView: PropTypes.bool.isRequired,
      isMainItemChannelCover: PropTypes.bool.isRequired,
      resetSelectedVideo: PropTypes.func.isRequired,
      mainVideoId: PropTypes.string,
      videoByIds: PropTypes.object.isRequired,
      currentSiteUser: PropTypes.object,
      isFetching: PropTypes.bool,
      requestPlay: PropTypes.func.isRequired,
      requestPause: PropTypes.func.isRequired,
      videoIds: PropTypes.array,
      prependedVideoIds: PropTypes.array,
      openVideoPage: PropTypes.func,
      openSubscribeOverlayFromWidget: PropTypes.func,
      canPlayVideoInFrame: PropTypes.bool,
      overlaySize: PropTypes.object,
      playerSize: PropTypes.object,
      windowSize: PropTypes.object,
      MainUINew: PropTypes.func, // for tests
      isVerticalLayoutMobile: PropTypes.bool.isRequired,
      playbackModuleLoaded: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        initialVideoId: props.mainVideoId,
      };
    }

    handleSubscribeClick = notForPreview(() => {
      this.props.openSubscribeOverlayFromWidget();
    });

    openMemberOnlyPage = (id) => {
      this.props.logWidgetSystem(
        'video.memberOnlyInfo.mobileOverlay.requested',
      );
      this.props.openMobileOverlay(
        `#/membership/${id}?shouldCloseMobileOverlay=true`,
      );
    };

    processDataFromStorage = ({ selectedVideoId }) => {
      if (selectedVideoId) {
        this.setState({ initialVideoId: selectedVideoId });
        this.props.selectVideo(selectedVideoId);
      }
    };

    storeDataForReload = () => {
      this.props.storeForReload({
        selectedVideoId: this.props.mainVideoId,
      });
    };

    playVideo = (videoItem) => {
      const {
        channelData,
        currentSiteUser,
        selectVideo,
        requestPlay,
        playback,
        fedops,
        isMobile,
      } = this.props;
      const { id } = videoItem;
      const state = { playback };
      const shouldSendPlayStartBi = isInitial(state, id) || isEnded(state, id);
      const isPlayAllowed = canPlayFullOrPreview(
        channelData,
        videoItem,
        currentSiteUser,
      );

      if (isPlayAllowed) {
        if (shouldSendPlayStartBi) {
          this.props.logWidgetVidClick({ videoItem, channelData });
        }
        requestPlay(id);
      } else {
        selectVideo(id);
      }
    };

    onCurrentSlideChanged = (newIndex) => {
      const { isFetching, videoIds, selectVideo } = this.props;

      if (videoIds[newIndex] && !isFetching) {
        selectVideo(videoIds[newIndex]);
      }
    };

    onPlayRequestedDisallowed = () => {
      const { mainVideoId } = this.props;
      this.props.openVideoPage(mainVideoId);
    };

    loadPlaybackModule = async () => {
      if (typeof window === 'undefined') {
        return () => null;
      }

      const module = await import(
        /* webpackChunkName: "mobile/playback" */ './components/playback/playback'
      );
      this.props.playbackModuleLoaded();
      return module.Playback;
    };

    showLogInForm = notForPreview(() => {
      this.props.sendBiEvent('widget.signIn.clicked');
      this.props.requestLogin().then(this.storeDataForReload);
    });

    reset = () => {
      this.props.resetSelectedVideo();
    };

    renderLayout() {
      return this.props.isVerticalLayoutMobile
        ? this.renderVerticalLayout()
        : this.renderCompactLayout();
    }

    renderVerticalLayout() {
      const {
        isVideoPlaying,
        channelData,
        playerSize,
        requestPause,
        mainVideo,
      } = this.props;

      return (
        <Vertical
          playerSize={playerSize}
          channelData={channelData}
          isVideoPlaying={isVideoPlaying}
          pauseVideo={requestPause}
          onSubscribeClick={this.handleSubscribeClick}
          openMemberOnlyPage={this.openMemberOnlyPage}
          onPlayRequestedDisallowed={this.onPlayRequestedDisallowed}
          showLogInForm={this.showLogInForm}
          playVideo={this.playVideo}
          loadPlaybackModule={this.loadPlaybackModule}
          mainVideo={mainVideo}
        />
      );
    }

    renderCompactLayout() {
      const {
        isVideoPlaying,
        canShowChannelCover,
        appSettings,
        videoByIds,
        videoIds,
        isFetching,
        channelData,
        currentSiteUser,
        mainVideoId,
        mainVideo,
        canPlayVideoInFrame,
        isMainItemChannelCover,
        isSingleVideoView,
        overlaySize,
        playerSize,
        openVideoPage,
        selectVideo,
        resetSelectedVideo,
        requestPause,
        openSubscribeOverlayFromWidget,
        playbackModuleLoaded,
      } = this.props;

      return (
        <Compact
          isVideoPlaying={isVideoPlaying}
          canShowChannelCover={canShowChannelCover}
          appSettings={appSettings}
          videoByIds={videoByIds}
          videoIds={videoIds}
          isFetching={isFetching}
          channelData={channelData}
          currentSiteUser={currentSiteUser}
          mainVideoId={mainVideoId}
          canPlayVideoInFrame={canPlayVideoInFrame}
          isMainItemChannelCover={isMainItemChannelCover}
          isSingleVideoView={isSingleVideoView}
          overlaySize={overlaySize}
          playerSize={playerSize}
          openVideoPage={openVideoPage}
          selectVideo={selectVideo}
          resetSelectedVideo={resetSelectedVideo}
          pauseVideo={requestPause}
          openSubscribeOverlayFromWidget={openSubscribeOverlayFromWidget}
          playbackModuleLoaded={playbackModuleLoaded}
          openMemberOnlyPage={this.openMemberOnlyPage}
          playVideo={this.playVideo}
          onCurrentSlideChanged={this.onCurrentSlideChanged}
          showLogInForm={this.showLogInForm}
          mainVideo={mainVideo}
          initialVideoId={this.state.initialVideoId}
          onSubscribeClick={this.handleSubscribeClick}
          onPlayRequestedDisallowed={this.onPlayRequestedDisallowed}
          loadPlaybackModule={this.loadPlaybackModule}
        />
      );
    }

    render() {
      return (
        <section
          data-hook="widget-container"
          data-app-version={this.props.staticsVersion}
          data-channel-layout="mobile"
          className={styles.container}
        >
          <PersistentEvents onEvent={this.processDataFromStorage} />
          <PaymentEvents
            onSubscription={this.reset}
            onSubscriptionCancel={this.reset}
          />
          {this.renderLayout()}
          <DeeplinkPopups />
          <MobilePerformanceLoggers />
        </section>
      );
    }
  },
);

export default withFedopsLogger(ConnectedHomeView);
