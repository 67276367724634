import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { isSliderLayout, isGridLayout } from '../../selectors/app-settings';
import { createSelector } from 'reselect';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import {
  getVideoIdToSelect,
  videoToSelectExists,
} from '../../selectors/video-id-to-select';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import { getVideosGroupedByIds } from '../../selectors/videos';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from './full-screen-modal';

const isPlayAllowed = createSelector(
  [
    getVideoIdToSelect,
    getChannelForWidget,
    getCurrentSiteUser,
    getVideosGroupedByIds,
  ],
  (videoIdToSelect, channelData, currentSiteUser, videos) => {
    return canPlayFullOrPreview(
      channelData,
      videos[videoIdToSelect],
      currentSiteUser,
    );
  },
);

export const openFullScreenVideoOnMount = () => (dispatch, getState) => {
  const state = getState();
  if (!videoToSelectExists(state)) {
    return;
  }

  if (!(isSliderLayout(state) || isGridLayout(state))) {
    return;
  }

  const videoIdToSelect = getVideoIdToSelect(state);
  const channelData = getChannelForWidget(state);
  const isAutoPlayable = isPlayAllowed(state);

  dispatch(
    openFullScreenVideoOverlay(
      channelData.id,
      videoIdToSelect,
      isAutoPlayable,
      () => dispatch(closeFullScreenVideoOverlay()),
    ),
  );
};
