import { createSelector } from 'reselect';
import {
  isExternalVideo,
  getChannelForWidget,
} from '@wix/wix-vod-shared/common';
import { getCurrentSiteUser } from './current-site-user';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import { prepareVideoCards } from '@wix/wix-vod-shared/dist/esm/widget/utils/video-cards';

export const isVideoCardsEnabled = createSelector(
  getCurrentSiteUser,
  getChannelForWidget,
  (state, videoItem) => videoItem,
  (currentSiteUser, channelData, videoItem) =>
    videoItem &&
    !isExternalVideo(videoItem) &&
    canAccessFullVideo(channelData, videoItem, currentSiteUser),
);

export const getVideoCardsList = (state) => state.videoCards;
export const getWidgetVideoCards = ({ thumbnailSize, imageHost }) =>
  createSelector(
    getVideoCardsList,
    getChannelForWidget,
    (videoCards, channelData) =>
      prepareVideoCards({
        channelId: channelData.id,
        videoCards,
        thumbnailSize,
        imageHost,
      }),
  );
