import { handleActions } from 'redux-actions';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/esm/widget/vod-player/components/share-buttons/constants';
import { SHARE_OVERLAY_ACTIONS } from '../actions/share-overlay';

export type ShareOverlayState = {
  isOpen: boolean;
  shareMode: SHARE_MODES | null;
};

const DEFAULT_STATE: ShareOverlayState = {
  isOpen: false,
  shareMode: null,
};

const toggleShareChannel = (state: ShareOverlayState) => ({
  ...state,
  isOpen: !state.isOpen,
  shareMode: SHARE_MODES.CHANNEL,
});

const toggleShareVideo = (state: ShareOverlayState) => ({
  ...state,
  isOpen: !state.isOpen,
  shareMode: SHARE_MODES.VIDEO,
});

export default handleActions<ShareOverlayState, void>(
  {
    [SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_CHANNEL]: toggleShareChannel,
    [SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_VIDEO]: toggleShareVideo,
    [SHARE_OVERLAY_ACTIONS.CLOSE]: () => DEFAULT_STATE,
  },
  DEFAULT_STATE,
);
