import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  focusSource,
  focusWithin,
  isWixCodeSEOEnabled,
} from '@wix/wix-vod-shared/common';

import { getAllSettings, getChannelLayout } from '../../selectors/app-settings';

import {
  isCurrentComponentIdInUrl,
  getVideoIdFromUrl,
} from '../../selectors/query-params';
import { isVideoPlayingOptimistic } from '../../selectors/video-playback-status';
import { openFullScreenVideoOnMount } from '../../redux/actions/full-screen-video-on-mount';
import PaymentEventProxy from '../../components/payment-events/proxy';
import { pauseVideo } from '../../redux/actions/player/change-playback-status';

import TooltipPopout from '../../components/tooltip/tooltip-popout';
import Modal from '../../components/modal/modal';

import BiHandler from '../../bi/bi-handler';
import { PersistentEventsHandler } from '../../containers/persistent-events-handler/persistent-events-handler';
import { LayoutSpinner } from '../../layouts/components/spinner/spinner';

import PlayerModuleLoader from '../../data-components/player-module-loader';

import WindowSize from '../../containers/window-size';
import { EditorObserver } from '../../components/editor-observer/editor-observer';
import { ViewerObserver } from '../../components/viewer-observer/viewer-observer';
import { AppSettingsObserver } from '../../data-components/app-settings-loader/app-settings-loader';
import { LoginWatcher } from '../../containers/login-watcher';
import ReloadChannelPaymentDataOnPayment from '../../data-components/reload-channel-payment-data-on-payment';
import ClearVideoUrlsCacheOnPayment from '../../data-components/clear-video-urls-cache-on-payment';
import PaymentsBiHandler from '../../components/payments/bi-handler';
import PremiumIntentUpdater from '../../components/premium-intent-updater/premium-intent-updater';
import { fitIntoView } from '../../worker/actions/fit-into-view';
import {
  getViewMode,
  isSiteMode,
  isEditorMode,
} from '../../selectors/view-mode';
import styles from '../../styles/core.scss';
import SEOStructuredData from '../seo/seo';
import { getCompId } from '../../redux/hydrated-data/hydrated-data';

const mapStateToProps = (state) => ({
  appSettings: getAllSettings(state),
  videoIdFromUrl: getVideoIdFromUrl(state),
  viewMode: getViewMode(state),
  compId: getCompId(state),
  layout: getChannelLayout(state),
  isCompIdInUrl: isCurrentComponentIdInUrl(state),
  isVideoPlaying: isVideoPlayingOptimistic(state),
  windowSize: state.windowSize,
  isSiteMode: isSiteMode(state),
  isEditorMode: isEditorMode(state),
});

const mapDispatchToProps = {
  pauseVideo,
  fitIntoView,
  openFullScreenVideoOnMount,
};

export const DesktopView = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class DesktopView extends React.Component {
    static propTypes = {
      windowSize: PropTypes.object.isRequired,
      children: PropTypes.node,
      videoIdFromUrl: PropTypes.string,
      appSettings: PropTypes.object,
      viewMode: PropTypes.string,
      isSiteMode: PropTypes.bool.isRequired,
      isEditorMode: PropTypes.bool.isRequired,
      layout: PropTypes.number,
      isCompIdInUrl: PropTypes.bool,
      singleVideoId: PropTypes.string,
      isVideoPlaying: PropTypes.bool,
      pauseVideo: PropTypes.func,
      fitIntoView: PropTypes.func.isRequired,
      compId: PropTypes.string,
    };

    componentDidMount() {
      // TODO: remove experiments fetching after opening video embed to all users
      this.focusWithinHandler = focusWithin();
      this.scrollToIfNecessary();
      this.props.openFullScreenVideoOnMount();
    }

    componentWillUnmount() {
      this.focusWithinHandler.disengage();
      focusSource.disengage(this.containerRef);
    }

    scrollToIfNecessary() {
      const { videoIdFromUrl, isCompIdInUrl, fitIntoView } = this.props;

      if (isCompIdInUrl || videoIdFromUrl) {
        fitIntoView();
        this.containerRef.scrollIntoView({ behavior: 'smooth' });
      }
    }

    saveContainerRef = (ref) => {
      if (ref) {
        focusSource.engage(ref);
      }
      this.containerRef = ref;
    };

    render() {
      const {
        children,
        appSettings,
        viewMode,
        layout,
        windowSize,
        isVideoPlaying,
        pauseVideo,
        isSiteMode,
        isEditorMode,
        compId,
      } = this.props;

      return (
        <WindowSize>
          {isWixCodeSEOEnabled() ? null : <SEOStructuredData />}
          <div
            style={
              isSiteMode ? { height: '100%' } : { height: windowSize.height }
            }
            ref={this.saveContainerRef}
            data-layout={layout}
            className={styles.root}
            data-view-mode={viewMode}
          >
            <BiHandler />
            <PaymentEventProxy />
            <PaymentsBiHandler />
            {isSiteMode || windowSize.width ? (
              <PlayerModuleLoader>
                <React.Fragment>
                  {children}
                  <PersistentEventsHandler />
                </React.Fragment>
              </PlayerModuleLoader>
            ) : (
              <LayoutSpinner />
            )}
            <AppSettingsObserver />
            <EditorObserver />
            <ViewerObserver
              isVideoPlaying={isVideoPlaying}
              pauseVideo={pauseVideo}
            />
            <ReloadChannelPaymentDataOnPayment />
            <ClearVideoUrlsCacheOnPayment />
            <LoginWatcher />

            <Modal settings={appSettings} />
            <TooltipPopout compId={compId} />

            {isEditorMode && <PremiumIntentUpdater />}
          </div>
        </WindowSize>
      );
    }
  },
);
