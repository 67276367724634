import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import focus from '@wix/wix-vod-shared/dist/esm/widget/utils/accessibility-focus';
import {
  areElementsRounded,
  isRTL,
} from '../../../../../selectors/app-settings';
import { getCompId } from '../../../../../redux/hydrated-data/hydrated-data';

import ClosableOverlay from '@wix/wix-vod-shared/dist/esm/widget/vod-player/components/closable-overlay/closable-overlay';
import VideoInfo from '../../components/video-info/video-info';
import Animate from '@wix/wix-vod-shared/dist/esm/widget/ui-components/animate/animate';

import INFO_OVERLAY_CONTENT from './info-overlay-content-enums';

import styles from '@wix/wix-vod-shared/dist/esm/widget/vod-player/components/actions-panel/actions-panel.scss';

const mapStateToProps = (state, ownProps) => ({
  compId: getCompId(state),
  isVideoInfoVisible:
    ownProps.overlayContent === INFO_OVERLAY_CONTENT.VIDEO_INFO,
  isAllCardsVisible: ownProps.overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS,
  areElementsRounded: areElementsRounded(state),
  isRTL: isRTL(state),
});

export default withTranslation()(
  connect(mapStateToProps)(
    class AnimatedOverlayContent extends React.Component {
      static propTypes = {
        channelData: PropTypes.object,
        videoItem: PropTypes.object,
        allCardsOverlayContent: PropTypes.node,
        overlayContent: PropTypes.string,
        onClose: PropTypes.func,

        compId: PropTypes.string,

        isVideoInfoVisible: PropTypes.bool,
        isAllCardsVisible: PropTypes.bool,

        isPlayerInFullScreen: PropTypes.bool,
        areElementsRounded: PropTypes.bool,
        isRTL: PropTypes.bool,
      };

      static defaultProps = {
        onClose: _.noop,
      };

      componentDidUpdate(prevProps) {
        const { overlayContent } = this.props;

        if (!overlayContent && prevProps.overlayContent === overlayContent) {
          return;
        }

        const hasChangedToTrue = (key) => this.props[key] && !prevProps[key];

        if (hasChangedToTrue('isVideoInfoVisible')) {
          focus(this.videoInfoContainerRef);
        }

        if (hasChangedToTrue('isAllCardsVisible')) {
          focus(this.allCardsContainerRef);
        }
      }

      videoInfoContainerRef = null;
      allCardsContainerRef = null;

      saveVideoInfoContainerRef = (ref) => {
        this.videoInfoContainerRef = ref;
      };

      saveAllCardsContainerRef = (ref) => {
        this.allCardsContainerRef = ref;
      };

      render() {
        const {
          videoItem: {
            posterUrl,
            title,
            description,
            publisher,
            durationStr,
            genre,
            videoType,
            cast,
            crew,
          },
          allCardsOverlayContent,
          overlayContent,
          isPlayerInFullScreen,
          isVideoInfoVisible,
          isAllCardsVisible,
          onClose,
          isRTL,
        } = this.props;

        const classNames = classnames(styles['info-overlay-wrapper'], {
          [styles.active]: overlayContent,
        });

        const infoClassName = styles['info-overlay'];

        return (
          <div className={classNames}>
            <section
              className={styles['info-overlay-content']}
              aria-hidden={!isVideoInfoVisible}
              ref={this.saveVideoInfoContainerRef}
            >
              <Animate
                type="fade-in-out"
                className={infoClassName}
                when={isVideoInfoVisible}
              >
                <ClosableOverlay
                  dataHook="closable-overlay"
                  onClose={onClose}
                  isInFullScreen={isPlayerInFullScreen}
                  posterUrl={posterUrl}
                  isVisible={isVideoInfoVisible}
                  isRTL={isRTL}
                  ariaLabel={this.props.t('widget.accessibility.video-info')}
                  closeButtonAriaLabel={this.props.t(
                    'widget.accessibility.close-video-info',
                  )}
                >
                  <VideoInfo
                    title={title}
                    description={description}
                    posterUrl={posterUrl}
                    publisher={publisher}
                    genre={genre}
                    videoType={videoType}
                    durationStr={durationStr}
                    durationAriaLabel={this.props.t(
                      'widget.accessibility.duration',
                      {
                        durationStr,
                      },
                    )}
                    cast={cast}
                    crew={crew}
                    isPlayerInFullScreen={isPlayerInFullScreen}
                    isRTL={isRTL}
                  />
                </ClosableOverlay>
              </Animate>
            </section>

            <section
              className={styles['info-overlay-content']}
              aria-hidden={!isAllCardsVisible}
              ref={this.saveAllCardsContainerRef}
            >
              <Animate className={infoClassName} when={isAllCardsVisible}>
                <ClosableOverlay
                  dataHook="all-cards-overlay"
                  onClose={onClose}
                  isVisible={isAllCardsVisible}
                  contentClassName={styles['all-cards-overlay-content']}
                  isFullWidth
                  isFullWidthForced
                  isInFullScreen={isPlayerInFullScreen}
                  isRTL={isRTL}
                  closeOnBodyClick
                  ariaLabel={this.props.t('widget.accessibility.all-cards')}
                  closeButtonAriaLabel={this.props.t(
                    'widget.accessibility.close-all-cards',
                  )}
                >
                  {isAllCardsVisible && allCardsOverlayContent}
                </ClosableOverlay>
              </Animate>
            </section>
          </div>
        );
      }
    },
  ),
);
