import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';

import Icon from '../../../../components/icon/icon';
import WithTooltip from '../with-tooltip/with-tooltip';
import ChannelInfoButton from '../../../../components/channel-actions/channel-info-button';
import ChannelSubscriptionLabel from '../../../../components/channel-subscription-label/channel-subscription-label';
import AccountButton from '../../../../components/account/account-button/account-button';
import OpenShareOverlay from '../../../../containers/open-share-overlay/open-share-overlay';
import ShareButton from '../../../../components/share-button/share-button';
import { sendBiEvent } from '../../../../bi/send-bi-event';
import { CurrencySign } from '@wix/wix-vod-shared/components';

import styles from './channel-actions.scss';
import { getCurrency } from '../../../../selectors/currency';

const mapStateToProps = null;
const mapDispatchToProps = { sendBiEvent };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class ChannelActions extends React.Component {
      static propTypes = {
        channelData: PropTypes.object,
        videoItem: PropTypes.object,

        canShowCloseButton: PropTypes.bool,
        canShowChannelShare: PropTypes.bool,
        canShowChannelInfo: PropTypes.bool,
        canShowSignIn: PropTypes.bool,
        canShowSubscriptionButton: PropTypes.bool,

        onShareRequest: PropTypes.func,
        onInfoRequest: PropTypes.func,
        onAccountInfoAtOverlayRequest: PropTypes.func,
        onSubscriptionRequest: PropTypes.func,
        onLogInRequest: PropTypes.func,
        onLogOutRequest: PropTypes.func,
        onSubscriptionCancelRequest: PropTypes.func,
        onCloseRequest: PropTypes.func,
      };

      componentDidMount() {
        const { canShowSubscriptionButton } = this.props;
        if (canShowSubscriptionButton) {
          this.props.sendBiEvent('widget.subscription.displayed', {
            whereDisplayed: 'widget',
          });
        }
      }

      wrapWithTooltip(content, tooltipMessage) {
        return (
          <WithTooltip
            tooltip={tooltipMessage}
            className={styles.item}
            innerClassName={styles['tooltip-inner']}
          >
            {content}
          </WithTooltip>
        );
      }

      getShareButton() {
        const tooltipMessage = this.props.t(
          'widget.compact-view.components.action-bar.share-tooltip-button',
        );
        return (
          <OpenShareOverlay>
            {({ toggleShare, ariaLabel }) =>
              this.wrapWithTooltip(
                <ShareButton
                  ariaLabel={ariaLabel}
                  className={`${styles.button} ${styles['share-button']}`}
                  onClick={toggleShare}
                />,

                tooltipMessage,
              )
            }
          </OpenShareOverlay>
        );
      }

      get channelInfoButton() {
        const { channelData, onInfoRequest, canShowChannelInfo } = this.props;

        if (!canShowChannelInfo) {
          return null;
        }

        const tooltipMessage = this.props.t(
          'widget.compact-view.components.action-bar.channel-info-tooltip',
        );
        const content = (
          <ChannelInfoButton
            className={`${styles.icon} ${styles['icon-channel-info']}`}
            channelData={channelData}
            onClick={onInfoRequest}
          />
        );

        return this.wrapWithTooltip(content, tooltipMessage);
      }

      handleSubscriptionClick = () => {
        this.props.onSubscriptionRequest();
      };

      get channelSubscriptionButton() {
        const { channelData, canShowSubscriptionButton, videoItem } =
          this.props;

        if (!canShowSubscriptionButton) {
          return null;
        }

        const tooltipMessage = (
          <ChannelSubscriptionLabel dealInfo={channelData.dealInfo} />
        );

        const currency = getCurrency(videoItem, channelData);

        const content = (
          <button
            className={styles.button}
            onClick={this.handleSubscriptionClick}
            data-hook="subscription-button"
            aria-label={this.props.t(
              'widget.accessibility.subscribe-to-channel',
            )}
          >
            <div className={`${styles.icon} ${styles['icon-paid']}`}>
              <CurrencySign currency={currency} />
            </div>
          </button>
        );

        return this.wrapWithTooltip(content, tooltipMessage);
      }

      get membershipActions() {
        const {
          channelData,
          onSubscriptionRequest,
          onAccountInfoAtOverlayRequest,
          canShowSignIn,
          onLogInRequest,
          onLogOutRequest,
        } = this.props;

        if (!canShowSignIn) {
          return null;
        }

        return (
          <AccountButton
            className={classnames(styles.item, styles.icon)}
            infoButtonClassName={styles['icon-account-info']}
            infoButtonTooltipClassName={styles['button-account-tooltip']}
            infoButtonPopoutTriangleClassName={
              styles['button-account-tooltip-triangle']
            }
            channelData={channelData}
            onClick={onAccountInfoAtOverlayRequest}
            onLoginClick={onLogInRequest}
            onLogoutClick={onLogOutRequest}
            onSubscribeClick={onSubscriptionRequest}
          />
        );
      }

      get closeButtonWithTooltip() {
        const { onCloseRequest } = this.props;

        const tooltipMessage = this.props.t(
          'widget.compact-view.components.action-bar.close',
        );
        const content = (
          <button
            className={styles.button}
            onClick={onCloseRequest}
            data-hook="close-action-bar"
            aria-label={this.props.t('widget.accessibility.close-video-list')}
          >
            <Icon
              name="exit"
              className={classnames(styles.icon, styles['icon-close'])}
            />
          </button>
        );

        return this.wrapWithTooltip(content, tooltipMessage);
      }

      get closeButtonWithLabel() {
        const { onCloseRequest } = this.props;

        const label = this.props.t(
          'widget.compact-view.components.action-bar.close',
        );

        return (
          <button
            className={classnames(styles.button, styles.item)}
            onClick={onCloseRequest}
            data-hook="close-action-bar"
            tabIndex="0"
          >
            <span className={styles.icon}>
              <Icon
                name="exit"
                className={classnames(
                  styles.icon,
                  styles['icon-close'],
                  styles['with-label'],
                )}
              />
              <span className={styles.label}>{label}</span>
            </span>
          </button>
        );
      }

      get closeButton() {
        const { canShowCloseButton } = this.props;

        if (!canShowCloseButton) {
          return null;
        }

        const {
          channelInfoButton,
          channelSubscriptionButton,
          membershipActions,
          closeButtonWithTooltip,
          closeButtonWithLabel,
        } = this;

        const buttonsExceptClose = [
          this.getShareButton(),
          channelInfoButton,
          channelSubscriptionButton,
          membershipActions,
        ];

        return _.some(buttonsExceptClose)
          ? closeButtonWithTooltip
          : closeButtonWithLabel;
      }

      render() {
        return (
          <nav className={styles.container}>
            {this.getShareButton()}
            {this.channelInfoButton}
            {this.channelSubscriptionButton}
            {this.membershipActions}
            {this.closeButton}
          </nav>
        );
      }
    },
  ),
);
