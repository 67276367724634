import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withFedopsLogger } from '@wix/yoshi-flow-editor';
import { logBi } from '../../worker/actions/bi';
import {
  BI_ORIGIN,
  BI_VIDEO_TYPE,
  getChannelForWidget,
  isPendingLiveStream,
  isScheduledLive,
} from '@wix/wix-vod-shared/common';

import { updateLiveStatus } from '../../redux/actions/videos';
import { fetchVideoUrls } from '../../redux/actions/video-urls/fetch';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import { selectVideo } from '../../redux/actions/select-video';
import { canAccessFullVideo } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import { isVideoOverlayOpened } from '../../selectors/full-screen-modal';
import {
  getLiveScheduledVideos,
  getLiveStreamingVideos,
  getLivePendingVideos,
} from '../../selectors/live-videos';
import LivePolling from '@wix/wix-vod-shared/dist/esm/widget/components/live-polling/live-polling';
import * as viewModeSelectors from '../../selectors/view-mode';
import { getPublicApi } from '../../api/public';

const getVideoData = (id) => async (dispatch, getState) => {
  const state = getState();
  const publicApi = getPublicApi();
  const channel = getChannelForWidget(state);
  return publicApi.channelVideos.getChannelVideoById(channel.id, id);
};

const mapStateToProps = (state) => ({
  channel: getChannelForWidget(state),
  currentSiteUser: getCurrentSiteUser(state),
  isVideoOverlayOpened: isVideoOverlayOpened(state),
  scheduledVideos: getLiveScheduledVideos(state),
  pendingVideos: getLivePendingVideos(state),
  streamingVideos: getLiveStreamingVideos(state),
  isPreviewOrEditorMode:
    viewModeSelectors.isPreviewMode(state) ||
    viewModeSelectors.isEditorMode(state),
});

const mapDispatchToProps = {
  updateLiveStatus,
  fetchVideoUrls,
  selectVideo,
  logBi,
  getVideoData,
};

const ConnectedLiveStartHandler = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class LiveStartHandler extends React.Component {
    static propTypes = {
      scheduledVideos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          dateStartLive: PropTypes.number,
        }),
      ),
      pendingVideos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
      streamingVideos: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        }),
      ),
      channel: PropTypes.object.isRequired,
      currentSiteUser: PropTypes.object,

      updateLiveStatus: PropTypes.func,
      fetchVideoUrls: PropTypes.func,
      playVideo: PropTypes.func,
      selectVideo: PropTypes.func,

      isVideoPlaying: PropTypes.bool,
      isVideoOverlayOpened: PropTypes.bool,
      isPreviewOrEditorMode: PropTypes.bool,

      selectedVideoId: PropTypes.string,
    };

    autoplayVideo = (video) => {
      const {
        playVideo,
        selectVideo,
        channel,
        currentSiteUser,
        isVideoOverlayOpened,
      } = this.props;
      if (this.isOtherVideoPlaying(video.id) || isVideoOverlayOpened) {
        return;
      }

      if (canAccessFullVideo(channel, video, currentSiteUser)) {
        this.logAutoplayBi(video);
        playVideo(video);
      } else {
        selectVideo(video.id);
      }
    };

    logAutoplayBi = (video) => {
      const { channel } = this.props;

      this.props.logBi('widget.video.autoplay.start', {
        videoType: BI_VIDEO_TYPE.LIVE,
        isEmbed: false,
        channelID: channel.id,
        videoID: video.id,
        origin: BI_ORIGIN.ON_SITE,
      });
    };

    logPollBi = (videoItem, error) => {
      this.props.logBi('widget.poll.request.sent', {
        videoType: videoItem && this.getVideoType(videoItem),
        isError: Boolean(error),
        errorMessage: _.get(error, 'message', null),
        origin: BI_ORIGIN.ON_SITE,
      });
    };

    getVideoType({ itemType, liveVideoStatus }) {
      let videoType = null;
      if (isPendingLiveStream({ liveVideoStatus })) {
        videoType = BI_VIDEO_TYPE.LIVE;
      } else if (isScheduledLive({ itemType })) {
        videoType = BI_VIDEO_TYPE.SCHEDULED;
      }
      return videoType;
    }

    isOtherVideoPlaying = (videoId) => {
      const { isVideoPlaying, selectedVideoId } = this.props;
      return isVideoPlaying && selectedVideoId !== videoId;
    };

    isOtherVideoSelected = (videoId) => {
      const { selectedVideoId } = this.props;
      return selectedVideoId && selectedVideoId !== videoId;
    };

    canInitiallyAutoplay = (videoId) => {
      return !this.isOtherVideoSelected(videoId);
    };

    onStreamChangePending = (videoItem) => {
      this.props.updateLiveStatus(videoItem);
    };

    onStreamChangeReady = (videoItem) => {
      this.props.updateLiveStatus(videoItem);
      this.props.fetchVideoUrls(videoItem, true);
    };

    render() {
      const {
        scheduledVideos,
        pendingVideos,
        streamingVideos,
        isPreviewOrEditorMode,
      } = this.props;

      if (isPreviewOrEditorMode) {
        return null;
      }

      return (
        <LivePolling
          scheduledVideos={scheduledVideos}
          pendingVideos={pendingVideos}
          streamingVideos={streamingVideos}
          autoplayVideo={this.autoplayVideo}
          canInitiallyAutoplay={this.canInitiallyAutoplay}
          onStreamChangePending={this.onStreamChangePending}
          onStreamChangeReady={this.onStreamChangeReady}
          onPoll={this.logPollBi}
          getVideoData={this.props.getVideoData}
        />
      );
    }
  },
);

export default withFedopsLogger(ConnectedLiveStartHandler);
