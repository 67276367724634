import _ from 'lodash';
import { handleActions } from 'redux-actions';
import {
  defaultSettings,
  defaultSettingsEditorX,
} from '@wix/wix-vod-constants/app-settings';
import { APP_SETTINGS_UPDATE } from '../actions/app-settings';
import { migrateToLatestVersion } from '@wix/wix-vod-shared/dist/esm/app-settings/migration';
import { isLatestVersion } from '../../utils/app-settings';

const initialSettings = {
  booleans: {},
  colors: {},
  fonts: {},
  numbers: {},
};

export default handleActions(
  {
    [APP_SETTINGS_UPDATE]: (state, { payload }) => {
      let currentSettings = _.merge({}, state, payload);

      if (!isLatestVersion(currentSettings)) {
        currentSettings = migrateToLatestVersion(currentSettings);
      }

      const isResponsiveEditor =
        payload.booleans && payload.booleans.responsive;

      return _.merge(
        {},
        initialSettings,
        isResponsiveEditor ? defaultSettingsEditorX : defaultSettings,
        currentSettings,
      );
    },
  },
  null,
);
