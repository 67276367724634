import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './menu-item.scss';
import KEY_CODES from '@wix/wix-vod-shared/dist/esm/widget/constants/key-codes';

export default class MenuItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    dataHook: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
  };

  handleKeyDown = (event) => {
    if (_.includes([KEY_CODES.SPACE, KEY_CODES.ENTER], event.keyCode)) {
      event.preventDefault();
      this.props.onClick();
      return;
    }

    const { previousElementSibling, nextElementSibling } = event.target;

    if (KEY_CODES.UP === event.keyCode) {
      event.preventDefault();
      if (previousElementSibling) {
        previousElementSibling.focus();
      }
    }

    if (KEY_CODES.DOWN === event.keyCode) {
      event.preventDefault();
      if (nextElementSibling) {
        nextElementSibling.focus();
      }
    }
  };

  render() {
    const { dataHook, onClick, children, icon, className } = this.props;

    return (
      <div
        data-hook={dataHook}
        role="menuitem"
        onKeyDown={this.handleKeyDown}
        tabIndex="0"
        className={classnames(styles.container, className)}
        onClick={onClick}
      >
        <span className={styles.icon}>{icon}</span>
        <span className={styles.text}>{children}</span>
      </div>
    );
  }
}
