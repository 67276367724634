import KEY_CODES from '@wix/wix-vod-shared/dist/esm/widget/constants/key-codes';

export function callOnEnterPressExactOnElement(callback, event) {
  if (
    event.keyCode !== KEY_CODES.ENTER ||
    event.target !== event.currentTarget
  ) {
    return;
  }

  event.stopPropagation();
  callback(event);
}

export function onEscPress(callback, event) {
  if (event.keyCode !== KEY_CODES.ESC) {
    return;
  }

  // event.stopPropagation();
  callback(event);
}
