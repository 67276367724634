import { t } from '../../utils/translation';

import {
  getAvailableVideoType,
  AVAILABLE_VIDEO_TYPES,
} from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';

export const DEFAULT_TRANSLATIONS = {
  [AVAILABLE_VIDEO_TYPES.FULL]: 'widget.overlay-buttons.play-video',
  [AVAILABLE_VIDEO_TYPES.TRAILER]: 'widget.overlay-buttons.watch-trailer',
  [AVAILABLE_VIDEO_TYPES.FIRST_20_SEC]: 'widget.overlay-buttons.watch-preview',
  [AVAILABLE_VIDEO_TYPES.LIVE_STREAMING]: 'widget.overlay-buttons.watch-live',
};

/**
 * Is channel play button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.currentSiteUser {Object}
 * @return {Boolean}
 */
export function isVisible({ channel, video, currentSiteUser }) {
  return Boolean(getAvailableVideoType(channel, video, currentSiteUser));
}

/**
 * Channel play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.currentSiteUser {Object}
 * @param props.translations {Object} see DEFAULT_TRANSLATIONS
 * @return {React.Component}
 */
export default function renderPlayButtonText({
  channel,
  video,
  currentSiteUser,
  translations = DEFAULT_TRANSLATIONS,
}) {
  const type = getAvailableVideoType(channel, video, currentSiteUser);

  if (!type) {
    return null;
  }
  return t(translations[type]);
}
