import React from 'react';
import PropTypes from 'prop-types';
import { Picture } from '@wix/wix-vod-shared/components';
import ResizedImage from '../resized-image/resized-image';
import {
  DEFAULT_VIDEO_HEIGHT,
  DEFAULT_VIDEO_WIDTH,
} from '../../constants/sizes';

const _propTypes = {
  thumbnailUrl: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  isResponsive: PropTypes.bool,
};

const _defaultProps = {
  width: DEFAULT_VIDEO_WIDTH,
  height: DEFAULT_VIDEO_HEIGHT,
};

class SimpleThumbnail extends React.Component {
  static propTypes = _propTypes;
  static defaultProps = _defaultProps;

  render() {
    const { thumbnailUrl, className, width, height, isResponsive } = this.props;

    if (isResponsive) {
      return (
        <Picture
          src={thumbnailUrl}
          withFallbackColor
          breakpoints={[
            {
              min: 0,
              width,
              height,
            },
          ]}
        />
      );
    }

    return (
      <ResizedImage
        src={thumbnailUrl}
        className={className}
        withFallbackColor
        width={width}
        height={height}
      />
    );
  }
}

export class SimpleVideoThumbnail extends React.Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  };
  static defaultProps = _defaultProps;

  render() {
    const {
      video: { coverUrl, customCoverUrl },
      width,
      height,
      className,
    } = this.props;

    return (
      <SimpleThumbnail
        thumbnailUrl={customCoverUrl || coverUrl}
        className={className}
        width={width}
        height={height}
      />
    );
  }
}

export class ResponsiveSimpleVideoThumbnail extends React.Component {
  static propTypes = {
    video: PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  };
  static defaultProps = _defaultProps;

  render() {
    const {
      video: { coverUrl, customCoverUrl },
      width,
      height,
      className,
    } = this.props;

    return (
      <SimpleThumbnail
        thumbnailUrl={customCoverUrl || coverUrl}
        className={className}
        width={width}
        height={height}
        isResponsive
      />
    );
  }
}
