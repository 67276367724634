import _ from 'lodash';
import { SEARCH_ACTIONS } from '../../actions/search';
import { SELECTED_VIDEO_ACTIONS } from '../../actions/select-video';
import { UPDATE_WINDOW_SIZE } from '../../actions/window-size';
import { APP_SETTINGS_UPDATE } from '../../actions/app-settings';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../../actions/channel/videos/list-public';
import { NAMES as CHANGE_PLAYBACK_STATUS } from '../../actions/player/change-playback-status';
import {
  loadMore,
  loadMoreVideoPages,
  resetLazyChannelVideos,
  goToLazyVideoIndex,
} from '../../lazy-channel-videos/actions';

import {
  isStripLayout,
  isCompactLayout,
  isGridLayout,
  isMainItemChannelCover,
  isSliderLayout,
} from '../../../selectors/app-settings';
import { getSearchQuery, getSearchQueryField } from '../../../selectors/search';
import {
  getVideoIds,
  getVideosPerPageCount,
  getCursor,
} from '../../lazy-channel-videos/selectors';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/selected-video-id';

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const prevState = getState();
    const returnValue = next(action); // eslint-disable-line callback-return
    const state = getState();

    const reloadLazyChannelVideos = () => {
      dispatch(resetLazyChannelVideos());
      dispatch(loadMoreVideoPages(2));
    };

    const setVideoIndexById = (videoId) => {
      const videoIndex = _.indexOf(getVideoIds(state), videoId);
      const cursor = getCursor(state);
      const isStrip = isStripLayout(state);
      const isCompact = isCompactLayout(state);
      const isLigthbox = state.isLightbox;

      if (videoIndex === -1 && cursor && (isStrip || isCompact || isLigthbox)) {
        dispatch(loadMoreVideoPages());
      }

      dispatch(goToLazyVideoIndex(videoIndex));
    };

    const loadMoreIfItemsPerPageChanged = ({ exact = false } = {}) => {
      const missingItemsCount =
        getVideosPerPageCount(state) -
        getVideoIds(state).length +
        (exact ? 0 : 1);

      const cursor = getCursor(state);
      const searchQuery = getSearchQuery(state);
      const searchQueryField = getSearchQueryField(state);

      if (missingItemsCount > 0 && cursor) {
        dispatch(
          loadMore(missingItemsCount, { [searchQueryField]: searchQuery }),
        );
      }
    };

    const updateMainVideoIndex = () => {
      if (isMainItemChannelCover(state) && !state.isLightbox) {
        return;
      }

      setVideoIndexById(getMainVideoId(state));
    };

    switch (action.type) {
      case SEARCH_ACTIONS.UPDATE_SEARCH:
      case SEARCH_ACTIONS.RESET_SEARCH:
        if (
          !_.isEqual(prevState.search, state.search) ||
          _.size(getVideoIds(state)) === 0
        ) {
          reloadLazyChannelVideos();
        }
        break;

      case SELECTED_VIDEO_ACTIONS.SET:
      case CHANGE_PLAYBACK_STATUS.REQUEST_PLAY:
        setVideoIndexById(action.payload);
        break;

      case UPDATE_WINDOW_SIZE:
        loadMoreIfItemsPerPageChanged({
          exact:
            isGridLayout(state) ||
            isCompactLayout(state) ||
            isStripLayout(state) ||
            isSliderLayout(state),
        });
        break;

      case APP_SETTINGS_UPDATE:
        if (isGridLayout(state)) {
          loadMoreIfItemsPerPageChanged({ exact: true });
        }
        break;

      case CHANNEL_VIDEOS_LIST.SUCCESS:
        updateMainVideoIndex();
        break;

      default:
        break;
    }

    return returnValue;
  };
