import _ from 'lodash';
import {
  getSalePriceInfo,
  getRentPriceInfo,
  getMinPriceInfo,
} from '@wix/wix-vod-shared/common';
import formatMessageWithPrice from '../../utils/format-message-with-price';
import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/dist/esm/widget/ui-selectors/video-access';
import { DEFAULT_TRANSLATIONS } from '../../utils/buy-button-texts/buy-button-texts';
import { t } from '../../utils/translation';

const PURCHASE_ACTION_NAMES = _.keys(DEFAULT_TRANSLATIONS);

/**
 * Is purchase video button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible({ channel, video }) {
  return _.includes(
    PURCHASE_ACTION_NAMES,
    getFullAccessActionName(channel, video),
  );
}

/**
 * Purchase video button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translations {Object} see DEFAULT_TRANSLATIONS
 * @return {React.Component}
 */
export default function renderPurchaseButtonText({
  channel,
  video,
  currentSiteUser,
  translations = DEFAULT_TRANSLATIONS,
  isVerticalLayoutMobile,
}) {
  switch (getFullAccessActionName(channel, video, currentSiteUser)) {
    case FULL_ACCESS_ACTION_NAMES.BUY:
      return formatMessageWithPrice(
        translations[FULL_ACCESS_ACTION_NAMES.BUY],
        getSalePriceInfo(video.dealInfo),
      );
    case FULL_ACCESS_ACTION_NAMES.RENT:
      return formatMessageWithPrice(
        translations[FULL_ACCESS_ACTION_NAMES.RENT],
        getRentPriceInfo(video.dealInfo),
      );
    case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
      return formatMessageWithPrice(
        translations[FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT],
        getMinPriceInfo(video.dealInfo),
      );
    case FULL_ACCESS_ACTION_NAMES.PRICING_PLAN:
      if (isVerticalLayoutMobile) {
        return t(translations[FULL_ACCESS_ACTION_NAMES.PRICING_PLAN]);
      }
      return null;
    case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
      if (isVerticalLayoutMobile) {
        return t(translations[FULL_ACCESS_ACTION_NAMES.SUBSCRIBE]);
      }

      return null;
    default:
      return null;
  }
}
