import { connect } from 'react-redux';
import { logWidgetSystem } from '../worker/actions/bi';
import React from 'react';
import { Direction } from '../containers/direction';
import HomeLayout from './layouts/home/home';
import SEOStructuredData from '../views/seo/seo';
import { isWixCodeSEOEnabled } from '@wix/wix-vod-shared/common';

const mapStateToProps = null;
const mapDispatchToProps = { logWidgetSystem };

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class Component extends React.Component {
    componentDidMount() {
      this.props.logWidgetSystem('mobile.app.initialized');
    }

    render() {
      return (
        <Direction>
          {isWixCodeSEOEnabled() ? null : <SEOStructuredData />}
          <HomeLayout />
        </Direction>
      );
    }
  },
);
