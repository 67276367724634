import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import MenuButton from '../menu-button/menu-button';
import Animate from '@wix/wix-vod-shared/dist/esm/widget/ui-components/animate/animate';
import styles from './action-bar.scss';

export default withTranslation()(
  class ActionBar extends React.Component {
    static propTypes = {
      children: PropTypes.any,
      height: PropTypes.number,
      areActionsVisible: PropTypes.bool,

      onActionsContainerClick: PropTypes.func,

      onMenuClick: PropTypes.func,
      onButtonsClick: PropTypes.func,
    };

    handleButtonsClick = (event) => {
      event.stopPropagation();
      this.props.onButtonsClick();
    };

    renderButtons() {
      const { onActionsContainerClick, children } = this.props;

      return (
        <div
          onClick={onActionsContainerClick}
          className={styles['clickable-container']}
        >
          <div className={styles.buttons} onClick={this.handleButtonsClick}>
            {children}
          </div>
        </div>
      );
    }

    renderMenuButton() {
      const { onMenuClick } = this.props;

      return (
        <div className={styles['clickable-container']} onClick={onMenuClick}>
          <MenuButton
            className={styles['menu-button']}
            ariaLabel={this.props.t('widget.accessibility.show-all-videos')}
          />
        </div>
      );
    }

    render() {
      const { areActionsVisible, height } = this.props;

      return (
        <div style={{ height }} className={styles.actions}>
          <Animate
            activeClassName={styles['actions-wrapper']}
            dataHook="compact-actions"
            type="fade-in"
            when={areActionsVisible}
          >
            {this.renderButtons()}
          </Animate>
          <Animate
            type="fade-in"
            activeClassName={styles['menu-button-wrapper']}
            dataHook="menu-button-wrapper"
            when={!areActionsVisible}
          >
            {this.renderMenuButton()}
          </Animate>
        </div>
      );
    }
  },
);
